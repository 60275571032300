import { useImmer } from "use-immer";
import { createPost } from "../../api";
import { useParams, useNavigate } from "react-router-dom";

export default function PageNew() {
  const { siteId } = useParams();
  const navigate = useNavigate();
  const [form, updateForm] = useImmer({
    name: "",
    description: "",
    slug: "",
  });
  const handleSubmit = () => {
    createPost(siteId, form)
      .then(({ data }) => {
        navigate(`/editor/${siteId}/posts/${data.postId}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleFormInput = (e) => {
    updateForm((draft) => {
      draft[e.target.id] = e.target.value;
    });
  };
  return (
    <form className="max-w-lg mx-auto">
      <h2>Create a new post</h2>
      <div className="mb-4">
        <label className="block font-bold mb-2" htmlFor="name">
          Name
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="name"
          type="text"
          value={form.name}
          onChange={handleFormInput}
          placeholder="Enter post name"
        />
      </div>
      <div className="mb-4">
        <label className="block font-bold mb-2" htmlFor="description">
          Description
        </label>
        <textarea
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="description"
          value={form.description}
          onChange={handleFormInput}
          placeholder="Enter post description"
        ></textarea>
      </div>
      <div className="flex items-center justify-between">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          onClick={handleSubmit}
          type="button"
        >
          Create
        </button>
      </div>
    </form>
  );
}
