import { useEffect, useRef } from "react";
import EditorJS from "@editorjs/editorjs";
import { getEditorTools } from "../../EditorTools";
import { isEqual, deepCopyObject } from "../../utilities";
import { useParams } from "react-router-dom";
import { savePost } from "../../api";

export default function BlockEditor(content) {
  const { postId } = useParams();
  const contentRef = useRef(content);
  const savedContentRef = useRef(content);
  const thisEditorRef = useRef(null);
  const editorTools = getEditorTools(postId);
  let editor = { isReady: false };
  useEffect(() => {
    if (thisEditorRef.current && !editor.isReady) {
      editor = new EditorJS({
        holderId: "thisEditor",
        tools: editorTools,
        autofocus: true,
        placeholder: "Let`s write an awesome story!",
        data: content.blocks,
        onReady: () => {
          console.log("Editor.js is ready to work!");
        },
        onChange: (api, event) => {
          api.saver
            .save()
            .then((outputData) => {
              contentRef.current = deepCopyObject(outputData);
            })
            .catch((error) => {
              console.log("Saving failed: ", error);
            });
        },
      });
      return () => console.log("this is the destroyer function", editor);
    }
  }, [thisEditorRef]);
  const autoSave = () => {
    if (!isEqual(contentRef.current, savedContentRef.current)) {
      savePost(postId, { content: contentRef.current })
        .then(({ data }) => {
          console.log("saved successfully", data);
          savedContentRef.current = deepCopyObject(contentRef.current);
        })
        .catch((err) => {
          console.log("error handling needs to be done for auto save", err);
        });
    }
  };
  useEffect(() => {
    const intervalId = setInterval(autoSave, 5000);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div className="prose w-full" id="thisEditor" ref={thisEditorRef}></div>
  );
}
