import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchSites } from "../../api";
export default function SiteList() {
  const [loading, setLoading] = useState(true);
  const [sites, setSites] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetchSites()
      .then(({ data }) => {
        setSites(data.sites);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);
  const selectSite = (e) => {
    e.preventDefault();
    navigate(`/editor/${e.target.id}`);
  };
  const siteCards = sites.map((site) => (
    <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-2 mb-4" key={site.id}>
      <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow">
        <div className="p-4">
          <h2 className="text-lg font-medium mb-2">{site.name}</h2>
          <p className="text-gray-600 mb-2">{`https://${site.name.replace(
            /\s/g,
            ""
          )}.indica.com`}</p>
          <div className="flex justify-end">
            <a
              id={site.id}
              onClick={selectSite}
              href="#"
              className="text-blue-600 hover:text-blue-800 font-medium"
            >
              Edit
            </a>
          </div>
        </div>
      </div>
    </div>
  ));
  return (
    <div className="flex flex-wrap -mx-2 p-32">
      {loading === true ? <h1>loading</h1> : siteCards}
      <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-2 mb-4">
        <div className="bg-white rounded-lg border-dashed border-2 border-gray-400 h-full flex flex-col items-center justify-center hover:border-gray-600 hover:shadow-lg transition-shadow">
          <div className="text-center">
            <svg
              className="w-12 h-12 text-gray-400 mb-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              ></path>
            </svg>
            <h2 className="text-lg font-medium mb-2">Add Site</h2>
            <a
              id="new"
              onClick={selectSite}
              href="#"
              className="text-blue-600 hover:text-blue-800 font-medium"
            >
              Create a new site
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
