import { saveUserImage, saveSiteImage } from "../api";
import { CameraIcon } from "@heroicons/react/24/solid";
import { ReactComponent as LoadingIcon } from "../assets/icons/loading.svg";
import { useState } from "react";

export default function UploadableImage({
  classAttributes,
  current,
  entity,
  entityId,
  picType,
  validation,
}) {
  //Implement validation logic - pic resolution, size etc
  const entitySetterMapping = {
    user: saveUserImage,
    site: saveSiteImage,
  };
  const [currentPic, setCurrentPic] = useState(current);
  const [uploadPending, setUploadPending] = useState(false);
  return (
    <label
      className={`group ${classAttributes} hover:grayscale flex flex-col justify-center items-center`}
      style={{
        backgroundImage: `url("${currentPic}")`,
        backgroundSize: `cover`,
      }}
    >
      <input
        type="file"
        accept="image/*"
        onChange={(event) => {
          event.preventDefault();
          setUploadPending(true);
          entitySetterMapping[entity](picType, event.target.files[0], entityId)
            .then(({ data }) => {
              if (data.success === true) {
                setCurrentPic(`${data.imageUrl}?rand=${Math.random()}`);
                setUploadPending(false);
              }
            })
            .catch((error) => {
              setUploadPending(false);
              console.error(error);
            });
        }}
        className="hidden"
      />
      {uploadPending === true ? (
        <LoadingIcon className="animate-spin fill-current text-white w-5 h-5 " />
      ) : (
        <>
          <CameraIcon
            className="hidden group-hover:flex h-5 w-5 text-white"
            aria-hidden="true"
          />
          <span className="hidden group-hover:flex text-white text-xs font-semibold">
            Upload
          </span>
        </>
      )}
    </label>
  );
}
