import SiteList from "../components/Site/SiteList";
import TopBar from "../components/TopBar";

export default function AppHome({user}) {
  console.log(user)
  return (
    <>
      <div className="min-h-full">
        <TopBar user={user}/>
        {/* <header className="bg-white shadow">
          <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">
              Dashboard
            </h1>
          </div>
        </header> */}
        <main>
          <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
            <SiteList />
          </div>
        </main>
      </div>
    </>
  );
}
