export const isEqual = function (obj1, obj2) {
  // Get the keys of the objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // If the number of keys is different, the objects are not equal
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check the values of each key recursively
  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    // If the values are objects, compare them recursively
    if (typeof val1 === "object" && typeof val2 === "object") {
      if (!isEqual(val1, val2)) {
        return false;
      }
    } else if (val1 !== val2) {
      // If the values are not objects, compare them directly
      return false;
    }
  }

  // If all the keys and values are the same, the objects are equal
  return true;
};

export const deepCopyArray = function (arr) {
  let copy = [];

  for (let i = 0; i < arr.length; i++) {
    let element = arr[i];

    if (Array.isArray(element)) {
      copy.push(deepCopyArray(element)); // recursive call for nested arrays
    } else if (typeof element === "object" && element !== null) {
      copy.push(deepCopyObject(element)); // recursive call for nested objects
    } else {
      copy.push(element); // primitive values are copied directly
    }
  }

  return copy;
};

export const deepCopyObject = function (obj) {
  let copy = {};

  for (let key in obj) {
    let value = obj[key];

    if (Array.isArray(value)) {
      copy[key] = deepCopyArray(value); // recursive call for nested arrays
    } else if (typeof value === "object" && value !== null) {
      copy[key] = deepCopyObject(value); // recursive call for nested objects
    } else {
      copy[key] = value; // primitive values are copied directly
    }
  }

  return copy;
};
