import { useState } from "react";
import { saveSiteImage } from "../../api";

export default function ProfilePic({ size, siteId, current }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentPic, setCurrentPic] = useState(current);
  const [uploadPending, setUploadPending] = useState(false);
  const handleFileInputChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setUploadPending(true);
  };
  const handleUploadClick = async (e) => {
    e.preventDefault();
    saveSiteImage(siteId, "profilePic", selectedFile)
      .then(({ data }) => {
        if (data.success === true) {
          console.log(data);
          setCurrentPic(data.imageUrl);
          setUploadPending(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="flex flex-col items-center">
      <label className="mb-2 cursor-pointer">
        <input
          type="file"
          accept="image/*"
          onChange={handleFileInputChange}
          className="hidden"
        />
        <div
          className={`rounded-full ${size} bg-gray-200 flex justify-center items-center cursor-pointer`}
        >
          {selectedFile || currentPic ? (
            <img
              src={
                uploadPending === true
                  ? URL.createObjectURL(selectedFile)
                  : currentPic
              }
              alt="profile pic"
              className="w-full h-full object-cover rounded-full"
            />
          ) : (
            <svg
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-8 h-8 text-gray-400"
            >
              <path
                fillRule="evenodd"
                d="M10 11a4 4 0 100-8 4 4 0 000 8zm7-4a7 7 0 11-14 0 7 7 0 0114 0z"
                clipRule="evenodd"
              />
              <path
                fillRule="evenodd"
                d="M3 15a2 2 0 002 2h10a2 2 0 002-2v-1.5a3.5 3.5 0 00-3.5-3.5h-5A3.5 3.5 0 003 13.5V15z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </div>
      </label>
      {uploadPending === true && (
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
          onClick={handleUploadClick}
        >
          Upload
        </button>
      )}
    </div>
  );
}
