// tools.js
// import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import List from "@editorjs/list";
import Warning from "@editorjs/warning";
import Code from "@editorjs/code";
import LinkTool from "@editorjs/link";
import ImageTool from "@editorjs/image";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import CheckList from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import { savePostImage } from "./api";

export const getEditorTools = (postId) => {
  const EDITOR_JS_TOOLS = {
    header: {
      class: Header,
      config: {
        placeholder: "Enter a header",
        levels: [1, 2, 3, 4, 5, 6],
        defaultLevel: 2,
        defaultAlignment: "left",
      },
    },
    image: {
      class: ImageTool,
      config: {
        uploader: {
          uploadByFile: (file) => {
            return savePostImage(postId, "postImage", file).then(({ data }) => {
              return {
                success: 1,
                file: {
                  url: data.imageUrl,
                  // any other image data you want to store, such as width, height, color, extension, etc
                },
              };
            });
          }, // Your backend file uploader endpoint
          uploadByUrl: (url) => console.log(url), // Your endpoint that provides uploading by Url
        },
      },
    },
    table: Table,
    list: List,
    code: Code,
    // embed: Embed,
    linkTool: LinkTool,
    quote: Quote,
    marker: Marker,
    checklist: CheckList,
    delimiter: Delimiter,
    inlineCode: InlineCode,
    warning: Warning,
  };
  return EDITOR_JS_TOOLS;
};
