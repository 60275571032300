import { useImmer } from "use-immer";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import BlockEditor from "./BlockEditor";
import { fetchPost } from "../../api";

export default function PostDetail() {
  const { postId } = useParams();
  const [form, updateForm] = useImmer({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchPost(postId)
      .then(({ data }) => {
        updateForm(data.post);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [postId]);

  const handleFormInput = (e) => {
    updateForm((draft) => {
      draft[e.target.id] = e.target.value;
    });
  };
  return loading === true ? (
    <h1>loading</h1>
  ) : (
    <>
      <form className="max-w-3xl mx-auto">
        <div className="mb-4">
          <label className="block font-bold mb-2" htmlFor="name">
            Name
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            type="text"
            value={form.name}
            onChange={handleFormInput}
            placeholder="Enter post name"
          />
        </div>
        <div className="mb-4">
          <label className="block font-bold mb-2" htmlFor="description">
            Description
          </label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-fit"
            id="description"
            value={form.description}
            onChange={handleFormInput}
            placeholder="Enter post description"
          ></textarea>
        </div>
      </form>
      <div className="max-w-3xl mx-auto pl-24 pt-10 h-full border-slate-900 border-2">
        <BlockEditor blocks={form.content} postId={postId} />
      </div>
    </>
  );
}
