import { useImmer } from "use-immer";
import { useEffect, useState } from "react";
import ProfilePic from "./ProfilePic";
import { useParams } from "react-router-dom";
import { fetchSite, saveSite } from "../../api";

export default function SiteDetail() {
  const { siteId } = useParams();
  const [form, updateForm] = useImmer({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchSite(siteId)
      .then(({ data }) => {
        updateForm(data.site);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [siteId]);

  //handlers
  const handleSubmit = () => {
    saveSite(siteId, { ...form }).then((res) => console.log(res));
  };
  const handleFormInput = (e) => {
    updateForm((draft) => {
      if (e.target.id.startsWith("social")) {
        draft.socialHandles[e.target.id.split("-")[1]] = e.target.value;
      } else {
        draft[e.target.id] = e.target.value;
      }
    });
  };

  // Presentation
  return loading === true ? (
    <h1>loading</h1>
  ) : (
    <div>
      <form className="max-w-lg mx-auto">
        <div className="flex justify-center items-center">
          <ProfilePic
            size="h-24 w-24"
            current={form.profilePic}
            siteId={siteId}
          />
        </div>
        <div className="mb-4">
          <label className="block font-bold mb-2" htmlFor="site-name">
            Site Name
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            type="text"
            value={form.name}
            onChange={handleFormInput}
            placeholder="Enter site name"
          />
        </div>
        <div className="mb-4">
          <label className="block font-bold mb-2" htmlFor="description">
            Description
          </label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="description"
            value={form.description}
            onChange={handleFormInput}
            placeholder="Enter site description"
          ></textarea>
        </div>
        <div className="mb-4">
          <label className="block font-bold mb-2" htmlFor="author-name">
            Author Name
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="authorName"
            type="text"
            value={form.authorName}
            onChange={handleFormInput}
            placeholder="Enter author name"
          />
        </div>
        <div className="mb-4">
          <label className="block font-bold mb-2" htmlFor="author-summary">
            Author Summary
          </label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="authorSummary"
            value={form.authorSummary}
            onChange={handleFormInput}
            placeholder="Enter author summary"
          ></textarea>
        </div>
        <div className="mb-4">
          <label className="block font-bold mb-2">Social Media Handles</label>
          <div className="flex flex-wrap -mx-2">
            <div className="w-full md:w-1/3 px-2 mb-4 md:mb-0">
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="social-twitter"
                type="text"
                value={
                  form.socialHandles.twitter ? form.socialHandles.twitter : ""
                }
                onChange={handleFormInput}
                placeholder="Twitter Handle"
              />
            </div>
            <div className="w-full md:w-1/3 px-2 mb-4 md:mb-0">
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="social-facebook"
                type="text"
                value={form.socialHandles.facebook}
                onChange={handleFormInput}
                placeholder="Facebook Handle"
              />
            </div>
            <div className="w-full md:w-1/3 px-2 mb-4 md:mb-0">
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="social-linkedin"
                type="text"
                value={form.socialHandles.linkedin}
                onChange={handleFormInput}
                placeholder="LinkedIn Handle"
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={handleSubmit}
            type="button"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}
