import Auth from "./layouts/Auth";
import Page404 from "./layouts/Page404";
import Login from "./components/Login";
import Signup from "./components/Signup";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState } from "react";
import AppHome from "./layouts/AppHome";
import SiteHome from "./layouts/SiteHome";
import SiteDetail from "./components/Site/SiteDetail";
import Protected from "./Protected";
import SiteNew from "./components/Site/SiteNew";
import PageList from "./components/Page/PageList";
import PageDetail from "./components/Page/PageDetail";
import PostList from "./components/Post/PostList";
import PostNew from "./components/Post/PostNew";
import PostDetail from "./components/Post/PostDetail";
import UserHome from "./layouts/UserHome";
import Profile from "./components/User/Profile";
import Billing from "./components/User/Billing";

function Router() {
  const loggedInUser =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));
  const [user, setUser] = useState(loggedInUser);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Auth />}>
          <Route index element={<Login setUser={setUser} />} />
          <Route path="login" element={<Login setUser={setUser} />} />
          <Route path="signup" element={<Signup setUser={setUser} />} />
          <Route path="*" element={<Page404 />} />
        </Route>
        <Route path="/user" element={<UserHome user={user} />}>
          <Route
            index
            element={
              <Protected>
                <Profile user={user} />
              </Protected>
            }
          />
          <Route
            path="profile"
            element={
              <Protected>
                <Profile user={user} />
              </Protected>
            }
          />
          <Route
            path="billing"
            element={
              <Protected>
                <Billing user={user} />
              </Protected>
            }
          />
        </Route>

        <Route
          path="editor"
          element={
            <Protected>
              <AppHome user={user} />
            </Protected>
          }
        />
        <Route
          path="editor/new"
          element={
            <Protected>
              <SiteNew />
            </Protected>
          }
        />
        <Route
          path="editor/:siteId"
          element={
            <Protected>
              <SiteHome user={user}/>
            </Protected>
          }
        >
          <Route
            index
            element={
              <Protected>
                <SiteDetail />
              </Protected>
            }
          />
          <Route
            path="/editor/:siteId/settings"
            element={
              <Protected>
                <SiteDetail />
              </Protected>
            }
          />
          <Route
            path="/editor/:siteId/pages"
            element={
              <Protected>
                <PageList />
              </Protected>
            }
          />
          <Route
            path="/editor/:siteId/pages/:pageId"
            element={
              <Protected>
                <PageDetail />
              </Protected>
            }
          />
          <Route
            path="/editor/:siteId/pages/new"
            element={
              <Protected>
                <PageDetail />
              </Protected>
            }
          />
          <Route
            path="/editor/:siteId/posts"
            element={
              <Protected>
                <PostList />
              </Protected>
            }
          />
          <Route
            path="/editor/:siteId/posts/:postId"
            element={
              <Protected>
                <PostDetail />
              </Protected>
            }
          />
          <Route
            path="/editor/:siteId/posts/new"
            element={
              <Protected>
                <PostNew />
              </Protected>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
