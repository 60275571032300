import { Outlet } from "react-router-dom";
import TopBar from "../components/TopBar";

export default function UserHome({ user }) {
  return (
    <>
      <div className="min-h-full">
        <TopBar user={user} />
        <main>
          <div className="mx-auto max-w-7xl md:py-6 md:px-6 lg:px-8">
            <Outlet />
          </div>
        </main>
      </div>
    </>
  );
}
