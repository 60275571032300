import { useImmer } from "use-immer";
import { useNavigate, NavLink } from "react-router-dom";
import { signup } from "../api";
import { useEffect, useState } from "react";
import { ReactComponent as ProductLogo } from "../logo.svg";
import { ReactComponent as GithubLogo } from "../assets/logos/github.svg";
import { ReactComponent as GoogleLogo } from "../assets/logos/google.svg";
import { ReactComponent as TwitterLogo } from "../assets/logos/twitter.svg";
import { ReactComponent as LoadingIcon } from "../assets/icons/loading.svg";
import { ReactComponent as EyeIcon } from "../assets/icons/eye.svg";
import { ReactComponent as EyeSlashIcon } from "../assets/icons/eye-slash.svg";

export default function Signup({ setUser }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [passwordMask, setPasswordMask] = useState(true);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/editor");
    }
  }, [setUser, navigate]);
  const [signUpForm, updateSignUpForm] = useImmer({
    email: "",
    password: "",
    error: null,
  });
  const handleFormInput = (e) => {
    updateSignUpForm((draft) => {
      draft[e.target.id] = e.target.value;
    });
  };
  const handleSubmit = () => {
    if (signUpForm.email && signUpForm.password) {
      setLoading(true);
      signup(signUpForm.email, signUpForm.password)
        .then(({ data }) => {
          setLoading(false);
          localStorage.setItem("token", data.token);
          localStorage.setItem("user", JSON.stringify(data.user));
          setUser(data.user);
          navigate("/editor");
        })
        .catch(({ response }) => {
          setLoading(false);
          updateSignUpForm((draft) => {
            draft.password = "";
            draft.error =
              (response.data && response.data.error) || "Something went wrong!";
          });
        });
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };
  return (
    <div className="flex flex-auto flex-col lg:flex-row">
      <div className="flex h-screen flex-row basis-1/2 pt-12 md:pt-32 lg:pt-0">
        <div className="w-4 grow shrink-0"></div>
        <div className="flex flex-col justify-center max-w-fit">
          <div className="mb-6">
            <ProductLogo className="h-12 w-12" />
          </div>
          <div className="mb-6">
            <p className="text-2xl md:text-3xl font-bold text-gray-700">
              Create your account
            </p>
            <p>
              <span className="text-sm font-light text-gray-500">
                Already have an account?{" "}
              </span>
              <NavLink
                className="text-sm font-medium text-indigo-600 underline"
                to="/login"
              >
                Sign in
              </NavLink>
            </p>
          </div>
          {/* Third party signin */}
          <div className="mb-6 w-64 md:w-96">
            <div className="text-sm font-medium mb-1 text-gray-700">
              Sign in with
            </div>
            <div className="flex flex-grow flex-row">
              <div className="flex w-1/3 h-9 flex-col border-gray-200 border-2 place-content-center items-center rounded-lg mr-2">
                <GoogleLogo className="fill-current text-gray-500  w-5 h-5 " />
              </div>
              <div className="flex w-1/3 h-9  border-gray-200 border-2 place-content-center items-center rounded-lg mr-2">
                <GithubLogo className="fill-current text-gray-500 w-5 h-5" />
              </div>
              <div className="flex w-1/3 h-9  border-gray-200 border-2 place-content-center items-center rounded-lg mr-2">
                <TwitterLogo className="fill-current text-gray-500 w-5 h-5" />
              </div>
            </div>
          </div>
          {/* divider */}
          <div className="flex items-center justify-center mb-6 w-64 md:w-96">
            <div className="border-t-2 border-gray-200 w-1/2" />
            <div className="px-1 font-light text-sm text-gray-500 w-1/2 text-center">
              Or continue with
            </div>
            <div className="border-t-2 border-gray-300 w-1/2" />
          </div>
          {/* Self signin process */}
          <div>
            <form className="w-64 md:w-96">
              <div className="mb-6">
                <label
                  className="text-sm font-medium mb-1 text-gray-700"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className="rounded-lg border-gray-200 border-2 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                  value={signUpForm.email}
                  onChange={handleFormInput}
                  onKeyDown={handleKeyDown}
                />
              </div>
              <div className="mb-6">
                <label
                  className="text-sm font-medium mb-1 text-gray-700"
                  htmlFor="password"
                >
                  Password
                </label>
                <div className="relative">
                  <input
                    className="rounded-lg border-gray-200 border-2 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="password"
                    type={passwordMask ? "password" : "text"}
                    value={signUpForm.password}
                    onChange={handleFormInput}
                    onKeyDown={handleKeyDown}
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                    {passwordMask ? (
                      <EyeSlashIcon
                        className="h-5 w-5 fill-current text-gray-500"
                        onClick={() => setPasswordMask(!passwordMask)}
                      />
                    ) : (
                      <EyeIcon
                        className="h-5 w-5 fill-current text-gray-500"
                        onClick={() => setPasswordMask(!passwordMask)}
                      />
                    )}
                  </div>
                </div>
              </div>
              {signUpForm.error && (
                <div className="mb-2">
                  <span className="text-sm font-medium text-red-400">
                    {signUpForm.error}
                  </span>
                </div>
              )}
              <div className="flex items-center justify-between">
                <button
                  className="flex flex-row justify-center bg-indigo-600 w-full hover:bg-indigo-500 py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={handleSubmit}
                >
                  {loading ? (
                    <LoadingIcon className="animate-spin fill-current text-indigo-100 w-5 h-5 mr-4 " />
                  ) : (
                    <span className="text-white font-medium text-sm">
                      COMPLETE SIGN UP
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="w-4 grow shrink-0"></div>
      </div>
      <div
        className="w-full h-screen  bg-cover bg-center basis-1/2"
        style={{
          backgroundImage: `url("https://images.unsplash.com/photo-1617791160536-598cf32026fb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1064&q=80")`,
        }}
      ></div>
    </div>
  );
}
