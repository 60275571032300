export default function TextInput({
  leadingText,
  icon,
  label,
  name,
  id,
  autoComplete,
  placeHolder,
  value,
  onChange,
}) {
  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="first-name"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="mt-2">
        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
          {(icon || leadingText) && (
            <div className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
              {icon && icon()}
              {leadingText && <span className="pl-2">{leadingText}</span>}
            </div>
          )}
          <input
            type="text"
            name={name}
            id={id}
            value={value}
            onChange={onChange}
            autoComplete={autoComplete}
            placeholder={placeHolder}
            className="block flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
    </div>
  );
}
