import { useImmer } from "use-immer";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchPage, savePage } from "../../api";

export default function PageDetail() {
  const { siteId, pageId } = useParams();
  const [form, updateForm] = useImmer({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPage(pageId)
      .then(({ data }) => {
        updateForm(data.page);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [pageId]);
  //   const [form, updateForm] = useImmer({
  //       name: "",
  //       description: "",
  //       slug: "",
  //       content: "",
  //   });
  const handleSubmit = () => {
    savePage(pageId, form).then(({ data }) => {
      console.log(data);
    });
  };
  const handleFormInput = (e) => {
    updateForm((draft) => {
      draft[e.target.id] = e.target.value;
    });
  };
  return loading === true ? (
    <h1>loading</h1>
  ) : (
    <>
      <form className="max-w-lg mx-auto">
        <h2>Create a page in your blog.</h2>
        <div className="mb-4">
          <label className="block font-bold mb-2" htmlFor="name">
            Name
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            type="text"
            value={form.name}
            onChange={handleFormInput}
            placeholder="Enter post name"
          />
        </div>
        <div className="mb-4">
          <label className="block font-bold mb-2" htmlFor="description">
            Description
          </label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="description"
            value={form.description}
            onChange={handleFormInput}
            placeholder="Enter post description"
          ></textarea>
        </div>

        <div className="mb-4">
          <label className="block font-bold mb-2" htmlFor="content">
            Content
          </label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="content"
            value={form.content}
            onChange={handleFormInput}
            placeholder="Type your content"
          ></textarea>
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={handleSubmit}
            type="button"
          >
            Save
          </button>
        </div>
      </form>
      <div
        className="w-1/1  mt-12 mr-12 ml-12 py-12 pt-10 h-auto bg-red-100"
        id="custom"
      ></div>
    </>
  );
}
