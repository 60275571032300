import { useEffect, useState } from "react";
import { fetchPosts } from "../../api";
import { useParams, useNavigate } from "react-router-dom";
export default function PostList() {
  const { siteId } = useParams();
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetchPosts(siteId)
      .then(({ data }) => {
        setPosts(data.posts);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);
  const selectPost = (e) => {
    e.preventDefault();
    navigate(`/editor/${siteId}/posts/${e.target.id}`);
  };
  const postCards = posts.map((post) => (
    <div className="w-full md:w-1/1 lg:w-1/2 xl:w-1/3 px-2 mb-4" key={post.id}>
      <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow">
        <div className="p-4">
          <h2 className="text-lg font-medium mb-2">{post.name}</h2>
          <p className="text-gray-600 mb-2 line-clamp-3">{post.description}</p>
          <div className="flex justify-end">
            <a
              id={post.id}
              onClick={selectPost}
              href="#"
              className="text-blue-600 hover:text-blue-800 font-medium"
            >
              Edit
            </a>
          </div>
        </div>
      </div>
    </div>
  ));
  return (
    <div className="flex flex-wrap -mx-2 p-32">
      {loading === true ? <h1>loading</h1> : postCards}
      <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-2 mb-4">
        <div className="bg-white rounded-lg border-dashed border-2 border-gray-400 h-full flex flex-col items-center justify-center hover:border-gray-600 hover:shadow-lg transition-shadow">
          <div className="text-center">
            <h2 className="text-lg font-medium mb-2">Add Post</h2>
            <a
              id="new"
              onClick={selectPost}
              href="#"
              className="text-blue-600 hover:text-blue-800 font-medium"
            >
              Create a new Post
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
