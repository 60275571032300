import axios from "axios";

const API_HOST =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://indica-server.onrender.com";

const unauthenticatedRequest = (options) => {
  return axios.request(options);
};

const authenticatedRequest = (options, contentType) => {
  const token = localStorage.getItem("token");
  if (!token) {
    //logout
  } else {
    options.headers = {
      accept: "application/json",
      "content-type": contentType || "application/json",
      authorization: token,
    };
    return axios.request(options).catch(({ response }) => {
      if (response.status === 401) {
        localStorage.removeItem("token");
        window.location.assign("/login");
      }
    });
  }
};

//Authentication requests
export const login = (email, password) => {
  const options = {
    method: "POST",
    url: `${API_HOST}/auth/login`,
    data: {
      email,
      password,
    },
  };
  return unauthenticatedRequest(options);
};

export const signup = (email, password) => {
  const options = {
    method: "POST",
    url: `${API_HOST}/auth/signup`,
    data: {
      email,
      password,
    },
  };
  return unauthenticatedRequest(options);
};

// users fetch, create, find, update and picture uploads
export const fetchUser = (userId) => {
  const options = {
    method: "GET",
    url: `${API_HOST}/users/${userId}`,
  };
  return authenticatedRequest(options);
};
export const saveUserImage = (imageType, selectedFile) => {
  console.log("got called");
  const formData = new FormData();
  formData.append("image", selectedFile);
  formData.append("imageType", imageType);
  const options = {
    method: "POST",
    url: `${API_HOST}/users/images`,
    data: formData,
  };
  return authenticatedRequest(options, "multipart/form-data");
};

//sites fetch, create, find, update, publish and delete
export const fetchSites = () => {
  const options = {
    method: "GET",
    url: `${API_HOST}/sites`,
  };
  return authenticatedRequest(options);
};

export const fetchSite = (siteId) => {
  const options = {
    method: "GET",
    url: `${API_HOST}/sites/${siteId}`,
  };
  return authenticatedRequest(options);
};

export const createSite = (siteDetails) => {
  const options = {
    method: "POST",
    url: `${API_HOST}/sites`,
    data: siteDetails,
  };
  return authenticatedRequest(options);
};

export const saveSite = (siteId, siteDetails) => {
  const options = {
    method: "PATCH",
    url: `${API_HOST}/sites/${siteId}`,
    data: siteDetails,
  };
  return authenticatedRequest(options);
};

export const saveSiteImage = (imageType, selectedFile, siteId) => {
  const formData = new FormData();
  formData.append("image", selectedFile);
  formData.append("imageType", imageType);
  const options = {
    method: "POST",
    url: `${API_HOST}/sites/${siteId}/images`,
    data: formData,
  };
  return authenticatedRequest(options, "multipart/form-data");
};

export const publishSite = (serviceId) => {
  const options = {
    method: "GET",
    url: `${API_HOST}/sites/services/${serviceId}/publish`,
  };
  return authenticatedRequest(options);
};

export const publishStatusCheck = (serviceId, deployId) => {
  const options = {
    method: "GET",
    url: `${API_HOST}/sites/services/${serviceId}/deploys/${deployId}`,
  };
  return authenticatedRequest(options);
};

//posts
export const fetchPosts = (siteId) => {
  const options = {
    method: "GET",
    url: `${API_HOST}/posts`,
    params: { siteId },
  };
  return authenticatedRequest(options);
};

export const createPost = (siteId, postDetails) => {
  const options = {
    method: "POST",
    url: `${API_HOST}/posts`,
    data: {
      siteId,
      ...postDetails,
    },
  };
  return authenticatedRequest(options);
};

export const fetchPost = (postId) => {
  const options = {
    method: "GET",
    url: `${API_HOST}/posts/${postId}`,
  };
  return authenticatedRequest(options);
};

export const savePost = (postId, postDetails) => {
  const options = {
    method: "PATCH",
    url: `${API_HOST}/posts/${postId}`,
    data: postDetails,
  };
  return authenticatedRequest(options);
};

export const savePostImage = (postId, imageType, selectedFile) => {
  const formData = new FormData();
  formData.append("image", selectedFile);
  formData.append("imageType", imageType);
  const options = {
    method: "POST",
    url: `${API_HOST}/posts/${postId}/images`,
    data: formData,
  };
  return authenticatedRequest(options, "multipart/form-data");
};

//page
export const fetchPages = (siteId) => {
  const options = {
    method: "GET",
    url: `${API_HOST}/pages`,
    params: { siteId },
  };
  return authenticatedRequest(options);
};

export const fetchPage = (pageId) => {
  const options = {
    method: "GET",
    url: `${API_HOST}/pages/${pageId}`,
  };
  return authenticatedRequest(options);
};

export const savePage = (pageId, pageDetails) => {
  const options = {
    method: "PATCH",
    url: `${API_HOST}/pages/${pageId}`,
    data: pageDetails,
  };
  return authenticatedRequest(options);
};
