import React from "react";
import { Outlet, NavLink, useMatch, useParams } from "react-router-dom";
import TopBar from "../components/TopBar";
import SiteControls from "./SiteControls";
import { fetchSite } from "../api";
import { useEffect, useState } from "react";

const LeftNav = () => {
  const match = useMatch("/editor/:id/*");
  return (
    <div className="flex flex-col bg-gray-800 text-gray-100">
      <nav className="flex-grow">
        <NavLink
          to={`${match.pathnameBase}/posts`}
          className="flex items-center px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 mr-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
          Posts
        </NavLink>
        <NavLink
          to={`${match.pathnameBase}/pages`}
          className="flex items-center px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 mr-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h2m4 0h2a2 2 0 002-2V7a2 2 0 00-2-2h-2m-6 0a2 2 0 012-2h2a2 2 0 012 2v10a2 2 0 01-2 2h-2a2 2 0 01-2-2V5z"
            />
          </svg>
          pages
        </NavLink>
        <NavLink
          to={`${match.pathnameBase}/settings`}
          relative="path"
          className="flex items-center px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 mr-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
            />
          </svg>
          Settings
        </NavLink>
      </nav>
    </div>
  );
};

export default function SiteHome({user}) {
  const { siteId } = useParams();
  const [site, setSite] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchSite(siteId)
      .then(({ data }) => {
        console.log("===>", data.site);
        setSite(data.site);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [siteId]);
  return (
    <>
      <div className="min-h-full">
        <TopBar user={user} />
        <header className="bg-white shadow">
          <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            {loading ? <></> : <SiteControls site={site} />}
          </div>
        </header>
        <main>
          <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
            {/*central content a.k.a workspace*/}
            <div className="flex flex-row">
              <LeftNav className="basis-1/4" />
              <div className="basis-3/4">
                <main className="flex-1 relative z-0 overflow-y-auto py-6 pt-4 focus:outline-none">
                  <Outlet />
                </main>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
  return (
    <div className="h-screen flex flex-col overflow-hidden bg-gray-100">
      <header className="flex-shrink-0 z-20">
        <TopBar />
      </header>
      <div className="flex flex-1 overflow-hidden">
        <LeftNav />
        <div className="flex-1 overflow-auto ml-56 pl-4 pt-12">
          <main className="flex-1 relative z-0 overflow-y-auto py-6 pt-4 focus:outline-none">
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
}
